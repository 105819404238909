import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import LoginModal from 'partial/LoginModal';

import styles from './scss/Navbar.module.scss';

class Navbar extends Component {

    state = {
        open: false,
        is_open: false
    }

    toggleModal = (e) => {
        this.setState({
            open: !this.state.open
        })
    }

    toggle_menu = (data) =>{
    	this.setState({
    		is_open: data
    	})
    }


    handleLogout = () => {
        const ls = window.localStorage;
        let token = ls.token;
        ls.clear();
        window.location.href = `${process.env.REACT_APP_API_ENDPOINT}support/logout/?token=${token}`;
    }

    render() {
        const {open, is_open} = this.state;
        const {data} = this.props;
        
        return (
            <div id="Navbar" className={styles.navbar}>
                <div className={`${styles.desktop}`}>
                    <div className={styles.desktop__left}>
                        <Link to="/">
                            <img src={require('assets/img/Logo.png')} alt=""/>
                        </Link>
                    </div>
                    <div className={styles.desktop__right}>
                        {
                            data ? 
                            <>
                                <Link to="/ticket/create">Ticket</Link>
                                <span onClick={this.handleLogout}>{data.user.nickname} ( Logout )</span>
                            </> :
                            <span onClick={this.toggleModal}>Login</span> 
                        }
                    </div>
                </div>

                <div className={styles.mobile}>
                    <Link to="/">
                        <img src={require('assets/img/Logo.png')} alt=""/>
                    </Link>
                    
                    <div className={`${styles.hamburger} hamburger hamburger--spin ${is_open ? `is-active` : ``}`} onClick={()=>this.toggle_menu(!is_open)}>
                        <span className={`${styles.hamburger_box} hamburger-box`}>
                            <span className={`${styles.hamburger_inner} hamburger-inner`}>
                            </span>
                        </span>
                    </div>

                    <div className={`${styles.overlay_menu} ${is_open ? styles.overlay_menu_active :``}`} onClick={()=>this.toggle_menu(false)}>
                        <div className={`${styles.nav_menu} ${is_open ? styles.nav_menu_active :``}`}>
                            <ul>
                                <li>
                                    <Link to='/' onClick={()=>this.toggle_menu(false)}>Home</Link>
                                </li>
                                {
                                    data ? 
                                    <>
                                        <li>
                                            <Link to='/ticket/create' onClick={()=>this.toggle_menu(false)}>Ticket</Link>
                                        </li>
                                        <li>
                                            <span onClick={()=> {this.toggle_menu(false); this.handleLogout()}}>{data.user.nickname}<br/>( Logout )</span>
                                        </li>
                                    </> :
                                    <li>
                                        <span onClick={this.toggleModal}>Login</span> 
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>


                <LoginModal open={open} closeModal={this.toggleModal} />
            </div>
        );
    }
}

export default Navbar;