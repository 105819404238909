import React, { Component } from "react"
import { Link } from "react-router-dom"
import styles from "./scss/Home.module.scss"

import Title from "partial/Title"
// import FAQLink from "partial/Link"
import Carousel from "partial/Carousel"
import Loader from "partial/Loader"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as actions from "./redux/actions"
import * as actionsBanner from "base/redux/actions"

class Home extends Component {
	componentDidMount() {
		const { actions, bannerActions } = this.props
		actions.get_top_issues()
		bannerActions.put_banner(require("assets/img/Banner/Header_BG.jpg"))
	}

	populateGame = () => {
		const { games } = this.props

		if (!games) {
			return <Loader />
		}

		return games.map((data, index) => {
			if (data.id === 16) {
				return (
					<a key={index} href="http://help.ftm.garena.co.id" target="_blank" rel="noopener noreferrer" className={styles.game}>
						<div style={{ background: `url(${data.logo}) center/100% no-repeat` }} />
					</a>
				)
			} else {
				return (
					<Link key={index} to={`/${data.slug}/faq`} className={styles.game}>
						{/* <img src={data.logo} alt="" /> */}
						<div style={{ background: `url(${data.logo}) center/100% no-repeat` }} />
					</Link>
				)
			}
		})
	}

	render() {
		const { top_issue } = this.props.home

		return (
			<div id="Home" className="container pt-5 pb-5">
				<div className="row">
					<div className={`col-md-6 col-12 ${styles.content__container__left}`}>
						<div className="top">
							<Title>Top Issue</Title>
							<div>
								<Carousel data={top_issue} other="/all/list/top issue" />
								{/* <FAQLink other="/all/list/top issue" data={top_issue} /> */}
							</div>
						</div>
						<div className={styles.bottom}>
							<Title>Hubungi Kami</Title>
							<div className={styles.contact_us}>
								<a href="mailto:media@garena.co.id">
									<span>Relasi Media</span>
									<span>▶</span>
								</a>
								<a href="mailto:partnership@garena.co.id">
									<span>Relasi Kerjasama dan Partnership</span>
									<span>▶</span>
								</a>
								<Link to="/ticket/create">
									<span>Pertanyaan lain seputar game</span>
									<span>▶</span>
								</Link>
							</div>
							<div className={styles.social_cont}>
								<a
									className={styles.social_icons}
									href="https://www.linkedin.com/company/garena/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src="https://cdn.garenanow.com/gstaticid/Support/linkedin_logo.png" alt="" />
								</a>
								<a
									className={styles.social_icons}
									href="https://www.instagram.com/lifeatgarenaid/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src="https://cdn.garenanow.com/gstaticid/Support/instagram_logo.png" alt="" />
								</a>
							</div>
						</div>
					</div>
					<div className={`col-md-6 col-12`}>
						<Title>Game Assistance</Title>
						<div className={`${styles.content__container__right}`}>
							<Link to={`/ticket/create`} className={styles.game}>
								<div style={{ background: `url(${require("assets/img/Logo/Ticket.png")}) center/100% no-repeat` }} />
							</Link>
							{this.populateGame()}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return { home: state.home, games: state.main.games }
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		bannerActions: bindActionCreators(actionsBanner, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
